// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noteInputWrapper--UDBZ4 {\n  padding: 12px;\n  min-height: 178px;\n\n  background-color: #fff;\n  border-radius: 8px;\n  overflow: hidden;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: space-between;\n  gap: 12px;\n}\n\n.noteInputWrapper--UDBZ4:focus-within {\n  outline: 2px solid rgba(0, 0, 0, 0.5);\n  border-color: #fff;\n}\n\n.noteInput--5iimQ {\n  width: 100%;\n  border: 0;\n  outline: 0;\n\n  font-size: 16px;\n  line-height: 24px;\n  color: #333;\n  font-family: inherit;\n  resize: none;\n  background-color: inherit;\n}\n\n.noteInput--5iimQ::placeholder {\n  color: #666;\n}\n", "",{"version":3,"sources":["webpack://./src/components/dashboard/DailyLogWidget.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;;EAEjB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,oCAAoC;;EAEpC,aAAa;EACb,wBAAwB;EACxB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,SAAS;EACT,UAAU;;EAEV,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,oBAAoB;EACpB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".noteInputWrapper {\n  padding: 12px;\n  min-height: 178px;\n\n  background-color: #fff;\n  border-radius: 8px;\n  overflow: hidden;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: space-between;\n  gap: 12px;\n}\n\n.noteInputWrapper:focus-within {\n  outline: 2px solid rgba(0, 0, 0, 0.5);\n  border-color: #fff;\n}\n\n.noteInput {\n  width: 100%;\n  border: 0;\n  outline: 0;\n\n  font-size: 16px;\n  line-height: 24px;\n  color: #333;\n  font-family: inherit;\n  resize: none;\n  background-color: inherit;\n}\n\n.noteInput::placeholder {\n  color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noteInputWrapper": "noteInputWrapper--UDBZ4",
	"noteInput": "noteInput--5iimQ"
};
export default ___CSS_LOADER_EXPORT___;
