import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { useHistory } from "react-router-dom";

import { useStore } from "../../store";
import apiLib from "../../libs/apiLib";
const { ACCOUNT_STATUS_READY } = apiLib;

import PlaygroundsWidget from "./PlaygroundsWidget";
import SubscriptionsWidget from "./SubscriptionsWidget";
import DailyLogWidget from "./DailyLogWidget";
import LibraryWidget from "./LibraryWidget";
import OnboardingWidget from "./OnboardingWidget";
import AlertWidget from "./AlertWidget";  // <-- Import the AlertWidget

import { DEBUG } from "../../config";

import styles from "./Dashboard.module.css";

let forceFreshAccountStatus = false;
if (DEBUG) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  forceFreshAccountStatus = "fresh" in params || window.forceFreshAccountStatus;
}

const selector = (state) => ({
  name: state.user.name,
  isAccountActive: state.user.accountStatus === ACCOUNT_STATUS_READY,
});

export default function Dashboard() {
  const { name, isAccountActive } = useStore(selector, shallow);
  const history = useHistory();

  useEffect(() => {
    if (!isAccountActive) {
      // Force user into onboarding if account not active
      history.replace("/welcome");
    }
  }, [history, isAccountActive]);

  // Skip rendering the home screen if we're going to redirect on mount to the onboarding flow
  if (!isAccountActive) {
    return null;
  }

  return (
    <div className={styles.Dashboard}>
      <div
        className={styles.titleWrapper}
        style={{ marginLeft: "-10px", marginRight: "-10px" }}  // <-- Add negative margin
      >
        {name && <h1 className={styles.title}>Hello {name}</h1>}
        {!name && <h1 className={styles.title}>Welcome</h1>}
        <p className={styles.description}>Your account at a glance.</p>
        <AlertWidget />  
      </div>
      <div
        className={styles.widgetGrid}
        style={{ paddingLeft: "14px", paddingRight: "14px" }} // <-- Keep or adjust padding if needed
      >
        <OnboardingWidget forceFreshAccountStatus={forceFreshAccountStatus} />
        <DailyLogWidget forceFreshAccountStatus={forceFreshAccountStatus} />
        <PlaygroundsWidget forceFreshAccountStatus={forceFreshAccountStatus} />
        <SubscriptionsWidget forceFreshAccountStatus={forceFreshAccountStatus} />
        <LibraryWidget forceFreshAccountStatus={forceFreshAccountStatus} />
      </div>
    </div>
  );
}
