// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content--uWAKJ {\n  width: 100%;\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: flex-start;\n  gap: 20px;\n}\n\n.description--k7BkC {\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 450;\n  color: #333;\n}\n\n.description--k7BkC a {\n  color: #4240b9;\n  text-decoration: none;\n}\n\n.description--k7BkC a:hover {\n  text-decoration: underline;\n}\n\n.nav--497iT {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 8px;\n}\n\n.error--4GiCm {\n  background-color: #b94040;\n  color: #fff;\n  border-radius: 4px;\n  padding: 8px 12px;\n  font-size: 14px;\n  line-height: 21px;\n  text-align: center;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/components/integrations/Instapaper.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,wBAAwB;EACxB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,QAAQ;AACV;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".content {\n  width: 100%;\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: flex-start;\n  gap: 20px;\n}\n\n.description {\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 450;\n  color: #333;\n}\n\n.description a {\n  color: #4240b9;\n  text-decoration: none;\n}\n\n.description a:hover {\n  text-decoration: underline;\n}\n\n.nav {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 8px;\n}\n\n.error {\n  background-color: #b94040;\n  color: #fff;\n  border-radius: 4px;\n  padding: 8px 12px;\n  font-size: 14px;\n  line-height: 21px;\n  text-align: center;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content--uWAKJ",
	"description": "description--k7BkC",
	"nav": "nav--497iT",
	"error": "error--4GiCm"
};
export default ___CSS_LOADER_EXPORT___;
