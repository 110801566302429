// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KeptCard--Z2I5M {\n  color: #111;\n  background-color: #fffcd9;\n}\n\n.KeptCard--Z2I5M.isChildCard--vvV7T {\n  outline-color: #f4dd73;\n}\n\n.footer--T8r2b,\n.footer--T8r2b:hover /* Override inherited AttributeHeader hover styles */ {\n  border-top: 1px solid #f4dd73;\n  background-color: rgba(244, 221, 115, 0.3);\n  padding: 8px 12px;\n}\n", "",{"version":3,"sources":["webpack://./../web-shared-lib/src/components/card/KeptCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;EAEE,6BAA6B;EAC7B,0CAA0C;EAC1C,iBAAiB;AACnB","sourcesContent":[".KeptCard {\n  color: #111;\n  background-color: #fffcd9;\n}\n\n.KeptCard.isChildCard {\n  outline-color: #f4dd73;\n}\n\n.footer,\n.footer:hover /* Override inherited AttributeHeader hover styles */ {\n  border-top: 1px solid #f4dd73;\n  background-color: rgba(244, 221, 115, 0.3);\n  padding: 8px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"KeptCard": "KeptCard--Z2I5M",
	"isChildCard": "isChildCard--vvV7T",
	"footer": "footer--T8r2b"
};
export default ___CSS_LOADER_EXPORT___;
