// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editor--Kbjv8 {\n  width: 100%;\n  max-width: 650px;\n  margin: 0 auto;\n\n  font-size: 16px;\n  line-height: 28px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/editor/Editor.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;;EAEd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".editor {\n  width: 100%;\n  max-width: 650px;\n  margin: 0 auto;\n\n  font-size: 16px;\n  line-height: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": "editor--Kbjv8"
};
export default ___CSS_LOADER_EXPORT___;
