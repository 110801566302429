// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageHeader--GmEj- {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 12px;\n\n  max-width: 600px;\n}\n\n.PageHeader--GmEj- .title--POEAP {\n  color: #333;\n  font-size: 28px;\n  font-weight: 600;\n  line-height: 28px;\n  margin: 0;\n  padding: 0;\n}\n\n.PageHeader--GmEj-.small--ETmQe .title--POEAP {\n  font-size: 20px;\n  line-height: 24px;\n}\n\n.PageHeader--GmEj- .description--eC8Sr {\n  color: #65676b;\n  font-size: 16px;\n  line-height: 26px;\n  margin: 0;\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/page-header/PageHeader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,SAAS;;EAET,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,SAAS;EACT,UAAU;AACZ","sourcesContent":[".PageHeader {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 12px;\n\n  max-width: 600px;\n}\n\n.PageHeader .title {\n  color: #333;\n  font-size: 28px;\n  font-weight: 600;\n  line-height: 28px;\n  margin: 0;\n  padding: 0;\n}\n\n.PageHeader.small .title {\n  font-size: 20px;\n  line-height: 24px;\n}\n\n.PageHeader .description {\n  color: #65676b;\n  font-size: 16px;\n  line-height: 26px;\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageHeader": "PageHeader--GmEj-",
	"title": "title--POEAP",
	"small": "small--ETmQe",
	"description": "description--eC8Sr"
};
export default ___CSS_LOADER_EXPORT___;
