// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Downloads--pO\\+5G {\n}\n\n.contentWrapper--TWQfQ {\n  padding: 40px;\n  width: 100%;\n  max-width: 760px;\n\n  background-color: #fff;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 40px;\n}\n\n.header--Bg6AI {\n  flex: 1 1 100%;\n\n  padding: 12px;\n}\n\n.content--w8-jA {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/downloads/Downloads.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;;EAEhB,sBAAsB;;EAEtB,aAAa;EACb,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,cAAc;;EAEd,aAAa;AACf;;AAEA;EACE,aAAa;EACb,wBAAwB;EACxB,SAAS;AACX","sourcesContent":[".Downloads {\n}\n\n.contentWrapper {\n  padding: 40px;\n  width: 100%;\n  max-width: 760px;\n\n  background-color: #fff;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 40px;\n}\n\n.header {\n  flex: 1 1 100%;\n\n  padding: 12px;\n}\n\n.content {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Downloads": "Downloads--pO+5G",
	"contentWrapper": "contentWrapper--TWQfQ",
	"header": "header--Bg6AI",
	"content": "content--w8-jA"
};
export default ___CSS_LOADER_EXPORT___;
