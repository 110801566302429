// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Recall Filters */\n\n.RecallFilters--huR4a {\n  color: #777;\n  font-size: 14px;\n  line-height: 21px;\n}\n\n.RecallFilters--huR4a button {\n  background-color: #ededfd;\n  border-radius: 4px;\n  border: 0;\n  outline: 0;\n  overflow: hidden;\n  color: #4240b9;\n  font-size: inherit;\n  line-height: inherit;\n  margin: 2px 0;\n  transition: background-color 100ms linear;\n  font-weight: 500;\n}\n\n.RecallFilters--huR4a button:hover {\n  background-color: #dadafb;\n}\n\n/* Menu */\n\n.menu--RTWOc {\n  z-index: 1;\n}\n", "",{"version":3,"sources":["webpack://./../web-shared-lib/src/components/recall/RecallFilters.module.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,oBAAoB;EACpB,aAAa;EACb,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,SAAS;;AAET;EACE,UAAU;AACZ","sourcesContent":["/* Recall Filters */\n\n.RecallFilters {\n  color: #777;\n  font-size: 14px;\n  line-height: 21px;\n}\n\n.RecallFilters button {\n  background-color: #ededfd;\n  border-radius: 4px;\n  border: 0;\n  outline: 0;\n  overflow: hidden;\n  color: #4240b9;\n  font-size: inherit;\n  line-height: inherit;\n  margin: 2px 0;\n  transition: background-color 100ms linear;\n  font-weight: 500;\n}\n\n.RecallFilters button:hover {\n  background-color: #dadafb;\n}\n\n/* Menu */\n\n.menu {\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RecallFilters": "RecallFilters--huR4a",
	"menu": "menu--RTWOc"
};
export default ___CSS_LOADER_EXPORT___;
