// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextSelectionMenu--rZRG1 {\n  position: fixed;\n  z-index: 2147483647;\n\n  min-width: 150px;\n  border: 1px solid #ccc;\n  margin: 8px;\n  background: #fff;\n  border-radius: 4px;\n  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);\n  overflow: hidden;\n\n  user-select: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/reader/TextSelectionMenu.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;;EAEnB,gBAAgB;EAChB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,0CAA0C;EAC1C,gBAAgB;;EAEhB,iBAAiB;AACnB","sourcesContent":[".TextSelectionMenu {\n  position: fixed;\n  z-index: 2147483647;\n\n  min-width: 150px;\n  border: 1px solid #ccc;\n  margin: 8px;\n  background: #fff;\n  border-radius: 4px;\n  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);\n  overflow: hidden;\n\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextSelectionMenu": "TextSelectionMenu--rZRG1"
};
export default ___CSS_LOADER_EXPORT___;
