// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MoreButton--VrVYI {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  height: 21px;\n  margin: 0;\n  padding: 0 12px;\n\n  background-color: #fff;\n  border-radius: 4px;\n  border: 1px solid #ccc;\n  transition: background-color 100ms linear;\n  user-select: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/playgrounds/MoreButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,YAAY;EACZ,SAAS;EACT,eAAe;;EAEf,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,yCAAyC;EACzC,iBAAiB;AACnB","sourcesContent":[".MoreButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  height: 21px;\n  margin: 0;\n  padding: 0 12px;\n\n  background-color: #fff;\n  border-radius: 4px;\n  border: 1px solid #ccc;\n  transition: background-color 100ms linear;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MoreButton": "MoreButton--VrVYI"
};
export default ___CSS_LOADER_EXPORT___;
