// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes bounceIn--Qt12H {\n  from {\n    transform: translate3d(0, 200px, 0);\n  }\n  to {\n    transform: translate3d(0, 0, 0);\n  }\n}\n\n.content--Kut5Y {\n  position: fixed;\n  bottom: 12px;\n  left: 12px;\n  width: 350px;\n  background-color: #f3f4f3;\n  border-radius: 8px;\n  border: 1px solid #ccc;\n\n  padding: 12px;\n\n  animation: bounceIn--Qt12H 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n  z-index: 2;\n\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n}\n\n.content--Kut5Y a {\n  color: inherit;\n}\n\n.icon--DIBSZ {\n  flex: 0 0 auto;\n\n  margin: 4px;\n}\n\n.label--VWJ2i {\n  flex: 1 1 auto;\n\n  font-size: 14px;\n  line-height: 21px;\n  font-weight: 500;\n\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/offline-nag/OfflineNag.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,mCAAmC;EACrC;EACA;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;;EAEtB,aAAa;;EAEb,uEAAgE;EAChE,UAAU;;EAEV,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;;EAEd,WAAW;AACb;;AAEA;EACE,cAAc;;EAEd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;;EAEhB,gBAAgB;AAClB","sourcesContent":["@keyframes bounceIn {\n  from {\n    transform: translate3d(0, 200px, 0);\n  }\n  to {\n    transform: translate3d(0, 0, 0);\n  }\n}\n\n.content {\n  position: fixed;\n  bottom: 12px;\n  left: 12px;\n  width: 350px;\n  background-color: #f3f4f3;\n  border-radius: 8px;\n  border: 1px solid #ccc;\n\n  padding: 12px;\n\n  animation: bounceIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n  z-index: 2;\n\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n}\n\n.content a {\n  color: inherit;\n}\n\n.icon {\n  flex: 0 0 auto;\n\n  margin: 4px;\n}\n\n.label {\n  flex: 1 1 auto;\n\n  font-size: 14px;\n  line-height: 21px;\n  font-weight: 500;\n\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content--Kut5Y",
	"bounceIn": "bounceIn--Qt12H",
	"icon": "icon--DIBSZ",
	"label": "label--VWJ2i"
};
export default ___CSS_LOADER_EXPORT___;
