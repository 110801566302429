// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loadingBlink--ZdPDK {\n  0% {\n    opacity: 0;\n  }\n  20% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n.LoadingIndicator--BRLYp {\n  margin: 0 1px;\n}\n\n.LoadingIndicator--BRLYp > span {\n  animation: loadingBlink--ZdPDK 1.4s linear infinite;\n  animation-fill-mode: forwards;\n  font-size: 2em;\n  color: #666;\n  opacity: 1;\n}\n\n.LoadingIndicator--BRLYp.isInline--q\\+tld > span {\n  font-size: inherit;\n  color: inherit;\n}\n\n.LoadingIndicator--BRLYp span:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.LoadingIndicator--BRLYp span:nth-child(3) {\n  animation-delay: 0.4s;\n}\n", "",{"version":3,"sources":["webpack://./../web-shared-lib/src/components/loading-indicator/LoadingIndicator.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mDAA4C;EAC5C,6BAA6B;EAC7B,cAAc;EACd,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["@keyframes loadingBlink {\n  0% {\n    opacity: 0;\n  }\n  20% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n.LoadingIndicator {\n  margin: 0 1px;\n}\n\n.LoadingIndicator > span {\n  animation: loadingBlink 1.4s linear infinite;\n  animation-fill-mode: forwards;\n  font-size: 2em;\n  color: #666;\n  opacity: 1;\n}\n\n.LoadingIndicator.isInline > span {\n  font-size: inherit;\n  color: inherit;\n}\n\n.LoadingIndicator span:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.LoadingIndicator span:nth-child(3) {\n  animation-delay: 0.4s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingIndicator": "LoadingIndicator--BRLYp",
	"loadingBlink": "loadingBlink--ZdPDK",
	"isInline": "isInline--q+tld"
};
export default ___CSS_LOADER_EXPORT___;
