// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HowTo--m3YkX {\n  padding: 5vw;\n}\n\n.HowTo--m3YkX a {\n  text-decoration: none;\n  color: #4240b9;\n  font-weight: 500;\n}\n\n.HowTo--m3YkX a:hover {\n  text-decoration: underline;\n}\n\n.HowTo--m3YkX p {\n  line-height: 24px;\n}\n\n.wrapper--QStK1 {\n  max-width: 800px;\n  margin: 0 auto;\n}\n\n.video--4gzqD {\n  margin-top: 40px;\n}\n\n.button--gmyPR {\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/howto/HowTo.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".HowTo {\n  padding: 5vw;\n}\n\n.HowTo a {\n  text-decoration: none;\n  color: #4240b9;\n  font-weight: 500;\n}\n\n.HowTo a:hover {\n  text-decoration: underline;\n}\n\n.HowTo p {\n  line-height: 24px;\n}\n\n.wrapper {\n  max-width: 800px;\n  margin: 0 auto;\n}\n\n.video {\n  margin-top: 40px;\n}\n\n.button {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HowTo": "HowTo--m3YkX",
	"wrapper": "wrapper--QStK1",
	"video": "video--4gzqD",
	"button": "button--gmyPR"
};
export default ___CSS_LOADER_EXPORT___;
