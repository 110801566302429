// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row--sctLk {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 0;\n}\n\n.title--FfvJd {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n\n  gap: 4px;\n  width: 100%;\n\n  color: #333;\n}\n\n.title--FfvJd .icon--A-RLG {\n  flex: 0 0 auto;\n\n  display: flex;\n  align-items: center;\n}\n\n.title--FfvJd .label--BAGlK {\n  flex: 1 1 auto;\n  text-align: left;\n\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 600;\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.stats--yBW9- {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n\n  font-size: 14px;\n  line-height: 24px;\n  color: #777;\n}\n", "",{"version":3,"sources":["webpack://./src/components/dashboard/LibraryWidget.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,MAAM;AACR;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;;EAEnB,QAAQ;EACR,WAAW;;EAEX,WAAW;AACb;;AAEA;EACE,cAAc;;EAEd,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;;EAEhB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;;EAEhB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;;EAER,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".row {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 0;\n}\n\n.title {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n\n  gap: 4px;\n  width: 100%;\n\n  color: #333;\n}\n\n.title .icon {\n  flex: 0 0 auto;\n\n  display: flex;\n  align-items: center;\n}\n\n.title .label {\n  flex: 1 1 auto;\n  text-align: left;\n\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 600;\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.stats {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n\n  font-size: 14px;\n  line-height: 24px;\n  color: #777;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "row--sctLk",
	"title": "title--FfvJd",
	"icon": "icon--A-RLG",
	"label": "label--BAGlK",
	"stats": "stats--yBW9-"
};
export default ___CSS_LOADER_EXPORT___;
