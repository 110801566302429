// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo--l7E\\+D {\n  position: absolute;\n  top: 40px;\n  left: 40px;\n}\n\n.content--xzG6m {\n  margin: 40px;\n}\n\n.content--xzG6m a {\n  color: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/components/deactivated/Deactivated.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".logo {\n  position: absolute;\n  top: 40px;\n  left: 40px;\n}\n\n.content {\n  margin: 40px;\n}\n\n.content a {\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo--l7E+D",
	"content": "content--xzG6m"
};
export default ___CSS_LOADER_EXPORT___;
