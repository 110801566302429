// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OnboardingWidget--wbKuM {\n  background-color: #efefff;\n  border: 1px solid #c7c6ff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/dashboard/OnboardingWidget.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":[".OnboardingWidget {\n  background-color: #efefff;\n  border: 1px solid #c7c6ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OnboardingWidget": "OnboardingWidget--wbKuM"
};
export default ___CSS_LOADER_EXPORT___;
