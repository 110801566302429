// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoteCard--TKOvQ {\n  color: #333;\n\n  white-space: pre-wrap;\n}\n", "",{"version":3,"sources":["webpack://./../web-shared-lib/src/components/card/NoteCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;EAEX,qBAAqB;AACvB","sourcesContent":[".NoteCard {\n  color: #333;\n\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NoteCard": "NoteCard--TKOvQ"
};
export default ___CSS_LOADER_EXPORT___;
