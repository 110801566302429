// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login--s2-1V {\n  width: 100%;\n  min-height: 100vh;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/auth/Login.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;;EAEjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".Login {\n  width: 100%;\n  min-height: 100vh;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Login": "Login--s2-1V"
};
export default ___CSS_LOADER_EXPORT___;
