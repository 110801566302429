// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Echo--NfykA {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n\n.code--kEm0p {\n  display: block;\n  padding: 20px;\n  max-width: 800px;\n  background-color: #efefef;\n\n  word-break: break-all;\n  overflow-wrap: anywhere;\n\n  font-family: monospace;\n\n  display: flex;\n  gap: 12px;\n  flex-flow: column nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/components/echo/Echo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,aAAa;EACb,gBAAgB;EAChB,yBAAyB;;EAEzB,qBAAqB;EACrB,uBAAuB;;EAEvB,sBAAsB;;EAEtB,aAAa;EACb,SAAS;EACT,wBAAwB;AAC1B","sourcesContent":[".Echo {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n\n.code {\n  display: block;\n  padding: 20px;\n  max-width: 800px;\n  background-color: #efefef;\n\n  word-break: break-all;\n  overflow-wrap: anywhere;\n\n  font-family: monospace;\n\n  display: flex;\n  gap: 12px;\n  flex-flow: column nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Echo": "Echo--NfykA",
	"code": "code--kEm0p"
};
export default ___CSS_LOADER_EXPORT___;
