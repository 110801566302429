// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Integrations--dExwz {\n}\n\n.contentWrapper--udEZI {\n  padding: 40px;\n  width: 100%;\n  max-width: 760px;\n\n  background-color: #fff;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 40px;\n}\n\n.header--cHj9S {\n  flex: 1 1 100%;\n\n  padding: 12px;\n}\n\n.content--r6GOn {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 20px;\n}\n\n.comingSoonBtn--NxyCV {\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  padding: 0 12px;\n\n  font-size: 14px;\n  line-height: 30px;\n  color: #666;\n}\n", "",{"version":3,"sources":["webpack://./src/components/integrations/Integrations.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;;EAEhB,sBAAsB;;EAEtB,aAAa;EACb,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,cAAc;;EAEd,aAAa;AACf;;AAEA;EACE,aAAa;EACb,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;;EAEf,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".Integrations {\n}\n\n.contentWrapper {\n  padding: 40px;\n  width: 100%;\n  max-width: 760px;\n\n  background-color: #fff;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 40px;\n}\n\n.header {\n  flex: 1 1 100%;\n\n  padding: 12px;\n}\n\n.content {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 20px;\n}\n\n.comingSoonBtn {\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  padding: 0 12px;\n\n  font-size: 14px;\n  line-height: 30px;\n  color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Integrations": "Integrations--dExwz",
	"contentWrapper": "contentWrapper--udEZI",
	"header": "header--cHj9S",
	"content": "content--r6GOn",
	"comingSoonBtn": "comingSoonBtn--NxyCV"
};
export default ___CSS_LOADER_EXPORT___;
