// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SwitchRoot--Sh\\+Am {\n  display: block;\n  position: relative;\n  width: 43px;\n  height: 24px;\n  padding: 0;\n\n  background: #fff;\n  border: 1px solid #a1a1a1;\n  border-radius: 999px;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n\n.SwitchRoot--Sh\\+Am:focus {\n  background: #efefef;\n}\n\n.SwitchRoot--Sh\\+Am[data-state=\"checked\"] {\n}\n\n.SwitchThumb--Ixbpp {\n  display: block;\n  width: 18px;\n  height: 18px;\n  background: #6a6969;\n  border-radius: 18px;\n  transition: transform 100ms;\n  transform: translateX(3px);\n  will-change: transform;\n}\n\n.SwitchThumb--Ixbpp[data-state=\"checked\"] {\n  transform: translateX(20px);\n  background: #6261c5;\n}\n", "",{"version":3,"sources":["webpack://./../web-shared-lib/src/components/switch/Switch.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;;EAEV,gBAAgB;EAChB,yBAAyB;EACzB,oBAAoB;EACpB,6CAA6C;AAC/C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;AACA;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,0BAA0B;EAC1B,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,mBAAmB;AACrB","sourcesContent":[".SwitchRoot {\n  display: block;\n  position: relative;\n  width: 43px;\n  height: 24px;\n  padding: 0;\n\n  background: #fff;\n  border: 1px solid #a1a1a1;\n  border-radius: 999px;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n\n.SwitchRoot:focus {\n  background: #efefef;\n}\n\n.SwitchRoot[data-state=\"checked\"] {\n}\n\n.SwitchThumb {\n  display: block;\n  width: 18px;\n  height: 18px;\n  background: #6a6969;\n  border-radius: 18px;\n  transition: transform 100ms;\n  transform: translateX(3px);\n  will-change: transform;\n}\n\n.SwitchThumb[data-state=\"checked\"] {\n  transform: translateX(20px);\n  background: #6261c5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SwitchRoot": "SwitchRoot--Sh+Am",
	"SwitchThumb": "SwitchThumb--Ixbpp"
};
export default ___CSS_LOADER_EXPORT___;
