// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sentence--KQvBa + .sentence--KQvBa {\n  margin-left: 4px;\n}\n\n.sentence--KQvBa.isMatch--lTKh- {\n  font-weight: 600;\n}\n\n/* Quoted Tweet */\n\n.quotedTweetWrapper--q4SZ0 {\n}\n\n.quotedTweetUsername--a-fQm {\n  color: #666;\n  background-color: rgba(0, 0, 0, 0.05);\n  border-radius: 4px;\n  padding: 0 2px;\n  margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./../web-shared-lib/src/components/recall/ParagraphMatch.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,iBAAiB;;AAEjB;AACA;;AAEA;EACE,WAAW;EACX,qCAAqC;EACrC,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".sentence + .sentence {\n  margin-left: 4px;\n}\n\n.sentence.isMatch {\n  font-weight: 600;\n}\n\n/* Quoted Tweet */\n\n.quotedTweetWrapper {\n}\n\n.quotedTweetUsername {\n  color: #666;\n  background-color: rgba(0, 0, 0, 0.05);\n  border-radius: 4px;\n  padding: 0 2px;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sentence": "sentence--KQvBa",
	"isMatch": "isMatch--lTKh-",
	"quotedTweetWrapper": "quotedTweetWrapper--q4SZ0",
	"quotedTweetUsername": "quotedTweetUsername--a-fQm"
};
export default ___CSS_LOADER_EXPORT___;
