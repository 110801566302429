// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HighlightCard--GwW2Y {\n  color: #111;\n  background-color: #fffcd9;\n}\n\n.HighlightCard--GwW2Y.isChildCard--Mo7g4 {\n  outline-color: #f4dd73;\n}\n", "",{"version":3,"sources":["webpack://./../web-shared-lib/src/components/card/HighlightCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".HighlightCard {\n  color: #111;\n  background-color: #fffcd9;\n}\n\n.HighlightCard.isChildCard {\n  outline-color: #f4dd73;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HighlightCard": "HighlightCard--GwW2Y",
	"isChildCard": "isChildCard--Mo7g4"
};
export default ___CSS_LOADER_EXPORT___;
