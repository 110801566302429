// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row--1j04D {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 0;\n}\n\n.count--qY1Sr {\n  font-size: 42px;\n  line-height: 58px;\n  color: #333;\n  font-weight: 700;\n}\n\n.description--15wNW {\n  font-size: 14px;\n  line-height: 24px;\n  color: #777;\n}\n", "",{"version":3,"sources":["webpack://./src/components/dashboard/SubscriptionsWidget.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,MAAM;AACR;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".row {\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 0;\n}\n\n.count {\n  font-size: 42px;\n  line-height: 58px;\n  color: #333;\n  font-weight: 700;\n}\n\n.description {\n  font-size: 14px;\n  line-height: 24px;\n  color: #777;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "row--1j04D",
	"count": "count--qY1Sr",
	"description": "description--15wNW"
};
export default ___CSS_LOADER_EXPORT___;
