// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dashboard--6QQo8 {\n  padding: 40px;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 20px;\n\n  max-width: 1800px;\n}\n\n.titleWrapper--QWW2B {\n  padding: 20px;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 12px;\n}\n\n.title--t59Vw {\n  color: #333;\n  font-size: 36px;\n  font-weight: 600;\n  line-height: 36px;\n  margin: 0;\n  padding: 0;\n}\n\n.description--GHhSF {\n  color: #65676b;\n  font-size: 22px;\n  line-height: 32px;\n  margin: 0;\n  padding: 0;\n}\n\n.widgetGrid--eIJNJ {\n  display: block;\n\n  columns: 2 400px;\n  column-gap: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/dashboard/Dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,aAAa;EACb,wBAAwB;EACxB,SAAS;;EAET,iBAAiB;AACnB;;AAEA;EACE,aAAa;;EAEb,aAAa;EACb,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;;EAEd,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".Dashboard {\n  padding: 40px;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 20px;\n\n  max-width: 1800px;\n}\n\n.titleWrapper {\n  padding: 20px;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 12px;\n}\n\n.title {\n  color: #333;\n  font-size: 36px;\n  font-weight: 600;\n  line-height: 36px;\n  margin: 0;\n  padding: 0;\n}\n\n.description {\n  color: #65676b;\n  font-size: 22px;\n  line-height: 32px;\n  margin: 0;\n  padding: 0;\n}\n\n.widgetGrid {\n  display: block;\n\n  columns: 2 400px;\n  column-gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Dashboard": "Dashboard--6QQo8",
	"titleWrapper": "titleWrapper--QWW2B",
	"title": "title--t59Vw",
	"description": "description--GHhSF",
	"widgetGrid": "widgetGrid--eIJNJ"
};
export default ___CSS_LOADER_EXPORT___;
