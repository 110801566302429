// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconPickerButton--j9zXV {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 1px;\n\n  border-radius: 4px;\n  border: 1px solid #cdcfd2;\n  background-color: #cdcfd2;\n  overflow: hidden;\n}\n\n.IconPickerButton--j9zXV > .segment--xCwJ6:first-child {\n  border-radius: 4px 0 0 4px;\n}\n\n.IconPickerButton--j9zXV > .segment--xCwJ6:last-child {\n  border-radius: 0 4px 4px 0;\n}\n\n.segment--xCwJ6 {\n  flex: 0 0 auto;\n  border: 0 !important;\n  border-radius: 0;\n  outline: 0;\n}\n", "",{"version":3,"sources":["webpack://./../web-shared-lib/src/components/icon-picker-button/IconPickerButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,QAAQ;;EAER,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".IconPickerButton {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 1px;\n\n  border-radius: 4px;\n  border: 1px solid #cdcfd2;\n  background-color: #cdcfd2;\n  overflow: hidden;\n}\n\n.IconPickerButton > .segment:first-child {\n  border-radius: 4px 0 0 4px;\n}\n\n.IconPickerButton > .segment:last-child {\n  border-radius: 0 4px 4px 0;\n}\n\n.segment {\n  flex: 0 0 auto;\n  border: 0 !important;\n  border-radius: 0;\n  outline: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IconPickerButton": "IconPickerButton--j9zXV",
	"segment": "segment--xCwJ6"
};
export default ___CSS_LOADER_EXPORT___;
