// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardStack--In6XZ {\n  position: absolute;\n  top: 0;\n  left: 0;\n  contain: layout style;\n  backface-visibility: hidden;\n  will-change: transform;\n\n  background-color: rgba(0, 0, 0, 0.06);\n  pointer-events: none;\n  border-radius: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/editor/CardStack.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,qBAAqB;EACrB,2BAA2B;EAC3B,sBAAsB;;EAEtB,qCAAqC;EACrC,oBAAoB;EACpB,mBAAmB;AACrB","sourcesContent":[".CardStack {\n  position: absolute;\n  top: 0;\n  left: 0;\n  contain: layout style;\n  backface-visibility: hidden;\n  will-change: transform;\n\n  background-color: rgba(0, 0, 0, 0.06);\n  pointer-events: none;\n  border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CardStack": "CardStack--In6XZ"
};
export default ___CSS_LOADER_EXPORT___;
