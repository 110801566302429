import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { Route, Switch } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";

import { useStore } from "./store";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ChangePassword from "./components/settings/ChangePassword";
import DailyLog from "./components/daily-log";
import Dashboard from "./components/dashboard";
import Deactivated from "./components/deactivated";
import Echo from "./components/echo";
import Editor from "./components/editor";
import ErrorBoundary from "./components/ErrorBoundary";
import ExtensionSettings from "./components/settings/Extension";
import History from "./components/settings/History";
import HowTo from "./components/howto";
import Integrations from "./components/integrations";
import Downloads from "./components/downloads";
import Internal from "./components/internal";
import Layout from "./components/layout";
import Library from "./components/library";
import Logout from "./components/logout";
import ManageLists from "./components/settings/ManageLists";
import NoGoList from "./components/settings/NoGoList";
import NotFound from "./components/NotFound";
import Notifications from "./components/notifications";
import OldLibrary from "./components/library/OldLibrary";
import Onboarding from "./components/onboarding";
import Playgrounds from "./components/playgrounds";
import Prefs from "./components/prefs";
import Recover from "./components/recover";
import RssSubscriptions from "./components/rss-subscriptions";
import Settings from "./components/settings";
import Tour from "./components/tour";

import { ChangeLog } from "./components/common/change-log";
import { Login, Signup } from "./components/auth";

import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

const selector = (state) => ({
  needsDeactivationRedirect: state.user.status === "success" && state.user.isMarkedDeactivated,
});

function SparseDocumentRedirect() {
  const { hash } = useParams();

  useEffect(() => {
    // Base64 decode the redirect URL
    const url = atob(hash);
    if (url) {
      window.location.replace(url);
    }
  }, [hash]);

  return <p style={{ padding: 20 }}>Redirecting...</p>;
}

function AppleNoteRedirect() {
  const { hash, id } = useParams();
  useEffect(() => {
    // TODO build native URL to open Apple Note
    const url = "";
    if (url) {
      window.location.replace(url);
    }
  }, [hash, id]);

  return <p style={{ padding: 20 }}>Redirecting to Apple Notes...</p>;
}

export default function Routes() {
  const { needsDeactivationRedirect } = useStore(selector, shallow);
  const history = useHistory();

  useEffect(() => {
    if (needsDeactivationRedirect) {
      history.replace("/deactivation_notice");
    }
  }, [needsDeactivationRedirect, history]);

  return (
    <ErrorBoundary>
      <Switch>
        {/* Note: Keep the list of unauthenticated paths in sync with redirectToLoginIfNeeded in store.js */}
        <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/logout">
          <Logout />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/deactivation_notice">
          <Deactivated />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/signup">
          <Signup />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/recover">
          <Recover />
        </UnauthenticatedRoute>
        {/* */}
        <AuthenticatedRoute exact path="/">
          <Layout section={"home"} isMenuCollapsed={false}>
            <Dashboard />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/playgrounds">
          <Layout section={"playgrounds"} isMenuCollapsed={false}>
            <Playgrounds />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/playgrounds/archive">
          <Layout section={"playgrounds"} isMenuCollapsed={false}>
            <Playgrounds showArchived />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/playgrounds/drafts">
          <Layout section={"playgrounds"} isMenuCollapsed={false}>
            <Playgrounds showDrafts />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/playgrounds/trash">
          <Layout section={"playgrounds"} isMenuCollapsed={false}>
            <Playgrounds showTrashed />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/edit/:id">
          <Layout section={"playgrounds"} isMenuCollapsed={true}>
            <Editor layout="edit" />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/split/:id">
          <Layout section={"playgrounds"} isMenuCollapsed={true}>
            <Editor layout="split" />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/play/:id">
          <Layout section={"playgrounds"} isMenuCollapsed={true}>
            <Editor layout="play" />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/idea/:id">
          <Layout section={"playgrounds"} isMenuCollapsed={true}>
            <Editor layout={null} />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/library">
          <Layout section={"library"} isMenuCollapsed={false}>
            <Library />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/library/debug">
          <Layout section={"library"} isMenuCollapsed={false}>
            <OldLibrary />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings">
          <Layout section={"settings"} isMenuCollapsed={false}>
            <Settings />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/password">
          <ChangePassword />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/prefs">
          <Layout section={"settings"} isMenuCollapsed={false}>
            <Prefs />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/extension">
          <Layout section={"settings"} isMenuCollapsed={false}>
            <ExtensionSettings />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/lists">
          <Layout section={"settings"} isMenuCollapsed={false}>
            <ManageLists />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/nogo">
          <Layout section={"settings"} isMenuCollapsed={false}>
            <NoGoList />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/settings/history">
          <Layout section={"settings"} isMenuCollapsed={false}>
            <History />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/subscriptions">
          <Layout section={"subscriptions"} isMenuCollapsed={false}>
            <RssSubscriptions />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/integrations">
          <Layout section={"integrations"} isMenuCollapsed={false}>
            <Integrations />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/downloads">
          <Layout section={"downloads"} isMenuCollapsed={false}>
            <Downloads />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/integrations/:expanded">
          <Layout section={"integrations"} isMenuCollapsed={false}>
            <Integrations />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/welcome">
          <Onboarding />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/welcome/:step">
          <Onboarding />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/howto">
          <HowTo />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/tour">
          <Tour />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/echo">
          <Echo />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/notifications">
          <Layout section={"notifications"} isMenuCollapsed={false}>
            <Notifications />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/daily-log">
          <Layout section={"daily-log"} isMenuCollapsed={false}>
            <DailyLog />
          </Layout>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/internal">
          <Internal />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/sparse-document/:hash">
          <SparseDocumentRedirect />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/apple-note/:hash/ICNote/:id">
          <AppleNoteRedirect />
        </AuthenticatedRoute>
        {/* ChangeLog can't be authenticated because extension embeds it */}
        <Route exact path="/changelog">
          <ChangeLog />
        </Route>
        {/* Finally, catch all unmatched routes */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}
