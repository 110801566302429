// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes bounceIn--tHgGr {\n  from {\n    transform: translate3d(0, 200px, 0);\n  }\n  to {\n    transform: translate3d(0, 0, 0);\n  }\n}\n\n.content--NyTu2 {\n  position: fixed;\n  bottom: 12px;\n  left: 12px;\n  width: 416px;\n  background-color: #fff;\n  border-radius: 8px;\n  border: 1px solid #ccc;\n\n  padding: 16px 12px 12px 12px;\n\n  animation: bounceIn--tHgGr 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n  z-index: 2;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 16px;\n}\n\n.nav--TI-FO {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 8px;\n}\n\n.label--MxqJ0 {\n  font-size: 14px;\n  line-height: 21px;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/update-nag/UpdateNag.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,mCAAmC;EACrC;EACA;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;;EAEtB,4BAA4B;;EAE5B,uEAAgE;EAChE,UAAU;;EAEV,aAAa;EACb,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["@keyframes bounceIn {\n  from {\n    transform: translate3d(0, 200px, 0);\n  }\n  to {\n    transform: translate3d(0, 0, 0);\n  }\n}\n\n.content {\n  position: fixed;\n  bottom: 12px;\n  left: 12px;\n  width: 416px;\n  background-color: #fff;\n  border-radius: 8px;\n  border: 1px solid #ccc;\n\n  padding: 16px 12px 12px 12px;\n\n  animation: bounceIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);\n  z-index: 2;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 16px;\n}\n\n.nav {\n  display: flex;\n  flex-flow: row nowrap;\n  gap: 8px;\n}\n\n.label {\n  font-size: 14px;\n  line-height: 21px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content--NyTu2",
	"bounceIn": "bounceIn--tHgGr",
	"nav": "nav--TI-FO",
	"label": "label--MxqJ0"
};
export default ___CSS_LOADER_EXPORT___;
