// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Notifications--UaaMb {\n  padding: 40px;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 40px;\n}\n\n.feed--RzpWy {\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/notifications/Notifications.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,aAAa;EACb,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".Notifications {\n  padding: 40px;\n\n  display: flex;\n  flex-flow: column nowrap;\n  gap: 40px;\n}\n\n.feed {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Notifications": "Notifications--UaaMb",
	"feed": "feed--RzpWy"
};
export default ___CSS_LOADER_EXPORT___;
