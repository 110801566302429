import api from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!./TextSelectionMenu.module.css";

var options = {"attributes":{"nonce":"5d21df1a5df1636381eef261f49244e9"}};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};