// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HelpMenuButton--Pkx7O {\n  outline: none;\n}\n\n.HelpMenuButton--Pkx7O[data-state=\"open\"] {\n  background-color: #efefef;\n}\n\n.menu--5gZZ2 {\n  width: 298px;\n\n  z-index: 1;\n}\n\n.menuItem--xSxTb {\n  padding: 12px;\n}\n\n.feedbackMenuItem--PQKVY {\n  color: #4240b9;\n}\n", "",{"version":3,"sources":["webpack://./src/components/layout/HelpMenuButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;;EAEZ,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".HelpMenuButton {\n  outline: none;\n}\n\n.HelpMenuButton[data-state=\"open\"] {\n  background-color: #efefef;\n}\n\n.menu {\n  width: 298px;\n\n  z-index: 1;\n}\n\n.menuItem {\n  padding: 12px;\n}\n\n.feedbackMenuItem {\n  color: #4240b9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HelpMenuButton": "HelpMenuButton--Pkx7O",
	"menu": "menu--5gZZ2",
	"menuItem": "menuItem--xSxTb",
	"feedbackMenuItem": "feedbackMenuItem--PQKVY"
};
export default ___CSS_LOADER_EXPORT___;
