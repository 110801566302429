// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toolbar--3UzSa {\n  padding: 8px 12px;\n\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  user-select: none;\n}\n\n.moveActions--5wX0F {\n  display: grid;\n  grid-gap: 8px;\n  grid-auto-flow: column;\n}\n\n.cardActions--l3zpm {\n  display: grid;\n  grid-gap: 8px;\n  grid-auto-flow: column;\n}\n\n.moreMenu--phYer {\n  z-index: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/editor/CardsToolbar.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;;EAEjB,aAAa;EACb,qBAAqB;EACrB,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".Toolbar {\n  padding: 8px 12px;\n\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  user-select: none;\n}\n\n.moveActions {\n  display: grid;\n  grid-gap: 8px;\n  grid-auto-flow: column;\n}\n\n.cardActions {\n  display: grid;\n  grid-gap: 8px;\n  grid-auto-flow: column;\n}\n\n.moreMenu {\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Toolbar": "Toolbar--3UzSa",
	"moveActions": "moveActions--5wX0F",
	"cardActions": "cardActions--l3zpm",
	"moreMenu": "moreMenu--phYer"
};
export default ___CSS_LOADER_EXPORT___;
