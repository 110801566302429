// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChangeLogBadge--GGPV4 {\n  width: 100%;\n  text-align: left;\n}\n\n.ChangeLogBadge--GGPV4 .link--kQc\\+U {\n  display: block;\n  font-size: 14px;\n  color: #333;\n  margin-top: 12px;\n}\n\n.version--2QmsV {\n  color: #666;\n  font-size: 14px;\n  font-weight: 450;\n  line-height: 25px;\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  user-select: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/change-log/ChangeLogBadge.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,iBAAiB;;EAEjB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB","sourcesContent":[".ChangeLogBadge {\n  width: 100%;\n  text-align: left;\n}\n\n.ChangeLogBadge .link {\n  display: block;\n  font-size: 14px;\n  color: #333;\n  margin-top: 12px;\n}\n\n.version {\n  color: #666;\n  font-size: 14px;\n  font-weight: 450;\n  line-height: 25px;\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChangeLogBadge": "ChangeLogBadge--GGPV4",
	"link": "link--kQc+U",
	"version": "version--2QmsV"
};
export default ___CSS_LOADER_EXPORT___;
