// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RTE--VyC-O a {\n  color: #4240b9;\n  line-height: 1em;\n}\n\n.RTE--VyC-O h1 {\n  font-size: 28px;\n  line-height: 34px;\n  margin-bottom: 4%;\n}\n\n.RTE--VyC-O ul,\n.RTE--VyC-O ol {\n  margin: 0;\n  padding: 0 0 0 20px;\n}\n\n.RTE--VyC-O ul {\n  list-style-type: disc;\n}\n\n.RTE--VyC-O ul ul {\n  list-style-type: disc;\n}\n\n.RTE--VyC-O ul ul ul {\n  list-style-type: disc;\n}\n\n.RTE--VyC-O ol {\n  list-style-type: decimal;\n}\n\n.RTE--VyC-O ol ol {\n  list-style-type: decimal;\n}\n\n.RTE--VyC-O ol ol ol {\n  list-style-type: decimal;\n}\n\n.isHighlighted--BDS8m {\n  background-color: #fdf1ba;\n  cursor: pointer;\n  transition: background-color 80ms ease-out;\n}\n\n.isHighlighted--BDS8m:hover {\n  background-color: #fce788;\n}\n", "",{"version":3,"sources":["webpack://./src/components/editor/RTE.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;;EAEE,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,0CAA0C;AAC5C;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".RTE a {\n  color: #4240b9;\n  line-height: 1em;\n}\n\n.RTE h1 {\n  font-size: 28px;\n  line-height: 34px;\n  margin-bottom: 4%;\n}\n\n.RTE ul,\n.RTE ol {\n  margin: 0;\n  padding: 0 0 0 20px;\n}\n\n.RTE ul {\n  list-style-type: disc;\n}\n\n.RTE ul ul {\n  list-style-type: disc;\n}\n\n.RTE ul ul ul {\n  list-style-type: disc;\n}\n\n.RTE ol {\n  list-style-type: decimal;\n}\n\n.RTE ol ol {\n  list-style-type: decimal;\n}\n\n.RTE ol ol ol {\n  list-style-type: decimal;\n}\n\n.isHighlighted {\n  background-color: #fdf1ba;\n  cursor: pointer;\n  transition: background-color 80ms ease-out;\n}\n\n.isHighlighted:hover {\n  background-color: #fce788;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RTE": "RTE--VyC-O",
	"isHighlighted": "isHighlighted--BDS8m"
};
export default ___CSS_LOADER_EXPORT___;
