// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManageLists--7InGt {\n  padding: 40px;\n  max-width: 600px;\n\n  line-height: 24px;\n}\n\n.ManageLists--7InGt.isLoading--tzmt6 {\n  padding: 0 40px;\n}\n\n.info--oMssZ {\n  color: #777;\n  font-size: 14px;\n}\n\n.list--pUaDa {\n  margin: 20px 0;\n}\n\n.row--Gs2cA {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n}\n\n.checkbox--KCQJE {\n  margin-right: 8px;\n}\n\n.rule--vqT4X {\n  font-size: 16px;\n  line-height: 24px;\n}\n\n.toolbar--OwJ4z {\n  display: grid;\n  grid-gap: 8px;\n  grid-auto-flow: column;\n}\n\n.error--i98I0 {\n  color: darkred;\n  padding: 12px 22px;\n  background: pink;\n  border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/settings/ManageLists.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;;EAEhB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".ManageLists {\n  padding: 40px;\n  max-width: 600px;\n\n  line-height: 24px;\n}\n\n.ManageLists.isLoading {\n  padding: 0 40px;\n}\n\n.info {\n  color: #777;\n  font-size: 14px;\n}\n\n.list {\n  margin: 20px 0;\n}\n\n.row {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n}\n\n.checkbox {\n  margin-right: 8px;\n}\n\n.rule {\n  font-size: 16px;\n  line-height: 24px;\n}\n\n.toolbar {\n  display: grid;\n  grid-gap: 8px;\n  grid-auto-flow: column;\n}\n\n.error {\n  color: darkred;\n  padding: 12px 22px;\n  background: pink;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ManageLists": "ManageLists--7InGt",
	"isLoading": "isLoading--tzmt6",
	"info": "info--oMssZ",
	"list": "list--pUaDa",
	"row": "row--Gs2cA",
	"checkbox": "checkbox--KCQJE",
	"rule": "rule--vqT4X",
	"toolbar": "toolbar--OwJ4z",
	"error": "error--i98I0"
};
export default ___CSS_LOADER_EXPORT___;
