// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReaderLayout--ePldC {\n  background-color: #fdfdfb;\n  width: 100%;\n  height: 100%;\n  inset: 0;\n\n  display: flex;\n  flex-flow: column nowrap;\n  overflow: hidden;\n  align-items: center;\n}\n\n.ReaderLayout--ePldC .toolbar--s6jZG {\n  flex: 0 0 auto;\n  width: 100%;\n  border-bottom: 1px solid #ddd;\n  z-index: 1;\n}\n\n.ReaderLayout--ePldC .content--UUR7F {\n  flex: 1 1 auto;\n\n  display: flex;\n  flex-flow: row nowrap;\n  width: 100%;\n\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.readerWrapper--m2rDp {\n  flex: 1 1 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/editor/ReaderLayout.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,QAAQ;;EAER,aAAa;EACb,wBAAwB;EACxB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,6BAA6B;EAC7B,UAAU;AACZ;;AAEA;EACE,cAAc;;EAEd,aAAa;EACb,qBAAqB;EACrB,WAAW;;EAEX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".ReaderLayout {\n  background-color: #fdfdfb;\n  width: 100%;\n  height: 100%;\n  inset: 0;\n\n  display: flex;\n  flex-flow: column nowrap;\n  overflow: hidden;\n  align-items: center;\n}\n\n.ReaderLayout .toolbar {\n  flex: 0 0 auto;\n  width: 100%;\n  border-bottom: 1px solid #ddd;\n  z-index: 1;\n}\n\n.ReaderLayout .content {\n  flex: 1 1 auto;\n\n  display: flex;\n  flex-flow: row nowrap;\n  width: 100%;\n\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.readerWrapper {\n  flex: 1 1 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReaderLayout": "ReaderLayout--ePldC",
	"toolbar": "toolbar--s6jZG",
	"content": "content--UUR7F",
	"readerWrapper": "readerWrapper--m2rDp"
};
export default ___CSS_LOADER_EXPORT___;
