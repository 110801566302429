// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Prefs--iCGMw {\n  padding: 40px;\n\n  max-width: 600px;\n}\n\n.prefsTable--XBXfR {\n  border: 1px solid #ccc;\n\n  margin: 20px 0;\n}\n\ntd.labelRow--lRnZT {\n  width: 100%;\n}\n\ntd.valueRow--PC4vr {\n  min-width: 140px;\n\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\ntable.prefsTable--XBXfR td {\n  border-top: 1px solid #ccc;\n  padding: 8px;\n}\n\ntable.prefsTable--XBXfR tr:first-child td {\n  border-top: none;\n}\n\n.input--toR6S {\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  width: 100%;\n}\n\n.title--tPT1C {\n  display: block;\n  margin: 0;\n  font-size: 20px;\n}\n\n.description--qHOsc {\n  font-size: 14px;\n  color: #666;\n}\n", "",{"version":3,"sources":["webpack://./src/components/prefs/Prefs.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;;EAEtB,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;;EAEhB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".Prefs {\n  padding: 40px;\n\n  max-width: 600px;\n}\n\n.prefsTable {\n  border: 1px solid #ccc;\n\n  margin: 20px 0;\n}\n\ntd.labelRow {\n  width: 100%;\n}\n\ntd.valueRow {\n  min-width: 140px;\n\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\ntable.prefsTable td {\n  border-top: 1px solid #ccc;\n  padding: 8px;\n}\n\ntable.prefsTable tr:first-child td {\n  border-top: none;\n}\n\n.input {\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  width: 100%;\n}\n\n.title {\n  display: block;\n  margin: 0;\n  font-size: 20px;\n}\n\n.description {\n  font-size: 14px;\n  color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Prefs": "Prefs--iCGMw",
	"prefsTable": "prefsTable--XBXfR",
	"labelRow": "labelRow--lRnZT",
	"valueRow": "valueRow--PC4vr",
	"input": "input--toR6S",
	"title": "title--tPT1C",
	"description": "description--qHOsc"
};
export default ___CSS_LOADER_EXPORT___;
