// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../web-client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SwitchRoot--UO80S {\n  display: block;\n  position: relative;\n  width: 30px;\n  height: 18px;\n  padding: 0;\n\n  background: #b3b3b3;\n  border: 0;\n  border-radius: 2px;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n\n.SwitchRoot--UO80S:focus {\n  background: #afafaf;\n}\n\n.SwitchRoot--UO80S[data-state=\"checked\"] {\n  background: #4240b9;\n}\n\n.SwitchThumb--eVp2N {\n  display: block;\n  width: 14px;\n  height: 14px;\n  background: #fff;\n  border-radius: 2px;\n  transition: transform 100ms;\n  transform: translateX(2px);\n  will-change: transform;\n}\n\n.SwitchThumb--eVp2N[data-state=\"checked\"] {\n  transform: translateX(14px);\n}\n", "",{"version":3,"sources":["webpack://./../web-shared-lib/src/components/compact-switch/CompactSwitch.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;;EAEV,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,6CAA6C;AAC/C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,2BAA2B;EAC3B,0BAA0B;EAC1B,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".SwitchRoot {\n  display: block;\n  position: relative;\n  width: 30px;\n  height: 18px;\n  padding: 0;\n\n  background: #b3b3b3;\n  border: 0;\n  border-radius: 2px;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n}\n\n.SwitchRoot:focus {\n  background: #afafaf;\n}\n\n.SwitchRoot[data-state=\"checked\"] {\n  background: #4240b9;\n}\n\n.SwitchThumb {\n  display: block;\n  width: 14px;\n  height: 14px;\n  background: #fff;\n  border-radius: 2px;\n  transition: transform 100ms;\n  transform: translateX(2px);\n  will-change: transform;\n}\n\n.SwitchThumb[data-state=\"checked\"] {\n  transform: translateX(14px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SwitchRoot": "SwitchRoot--UO80S",
	"SwitchThumb": "SwitchThumb--eVp2N"
};
export default ___CSS_LOADER_EXPORT___;
