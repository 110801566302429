// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toolbar--NsndI {\n  width: 100%;\n  height: 100%;\n  padding: 4px;\n\n  display: flex;\n  justify-content: space-between;\n\n  /* Make sure floating top toolbar doesn't block pointer events below it */\n  pointer-events: none;\n}\n\n.Toolbar--NsndI > * {\n  pointer-events: initial;\n}\n\n.Toolbar--NsndI .leftCol--DLiOl {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  gap: 8px;\n  margin: 4px;\n}\n\n.Toolbar--NsndI .rightCol--cNpF5 {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  gap: 8px;\n  margin: 4px;\n}\n\n.backButton--ABbCs {\n  padding: 0 8px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/editor/TopToolbar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;;EAEZ,aAAa;EACb,8BAA8B;;EAE9B,yEAAyE;EACzE,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".Toolbar {\n  width: 100%;\n  height: 100%;\n  padding: 4px;\n\n  display: flex;\n  justify-content: space-between;\n\n  /* Make sure floating top toolbar doesn't block pointer events below it */\n  pointer-events: none;\n}\n\n.Toolbar > * {\n  pointer-events: initial;\n}\n\n.Toolbar .leftCol {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  gap: 8px;\n  margin: 4px;\n}\n\n.Toolbar .rightCol {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  gap: 8px;\n  margin: 4px;\n}\n\n.backButton {\n  padding: 0 8px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Toolbar": "Toolbar--NsndI",
	"leftCol": "leftCol--DLiOl",
	"rightCol": "rightCol--cNpF5",
	"backButton": "backButton--ABbCs"
};
export default ___CSS_LOADER_EXPORT___;
